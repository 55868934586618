@charset "CP852";
/*------------------------------------------------------------------
[Table of contents]

1. Elements styling
2. Styling for specific pages
3. Universal Classes
4. Header
5. Footer
6. Content
7. Responsive

[Color codes]

Default: #7399C6;
Grey dark: #2a2a2a;
Grey medium: #5a5a5a;
Grey light: #f3f3f3;

[Typography]

Body copy:		'Roboto', sans-serif; 14px;
Headers:		'Roboto', sans-serif;

-------------------------------------------------------------------*/

/* 1. Element Styling */

a {
  color: #002949;
}

a:hover h4 {
  color: #002949;
}

a h4 {
  color: #455560;
}

blockquote .cite footer {
  color: #455560;
}

hr.divider {
  border-color: #002949;
}

ul.list-links li a:hover {
  color: #002949;
}
body {
  color: #2b2b2b;
}

/* 2. Styling for specific pages */

.page-sub-page #page-content:after {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1f1f1', endColorstr='#ffffff', GradientType=0);
}

.background-color-default {
  background-color: #002949;
}

.background-color-default-darker {
  background-color: #455560;
}

.badge-bubble {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Gotham SSm", Helvetica, Arial, sans-serif;
  --display-navbar-collapse: block;
  -webkit-box-direction: normal;
  list-style: none;
  text-transform: none;
  box-sizing: border-box;
  white-space: nowrap;
  display: inline-block;
  font-size: 10px;
  line-height: 1;
  color: #fff;
  background-color: #002949;
  padding: 5px 7px;
  border-radius: 2px;
  font-weight: 400;
  vertical-align: middle;
  margin: -4px 0 -4px 5px;
  position: relative;
  -webkit-font-smoothing: auto;
  margin-left: auto;
  border-radius: 50%;
}

.badge-comment-bubble:before,
.badge-ellipsis:before {
  color: #002949;
}

.badge-comment-bubble {
  background-color: #002949;
}

.bucket-breadcrumb {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 1.42857;
  box-sizing: border-box;
  padding: 0;
  list-style: none;
  margin: 0 !important;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  position: relative;
  height: 100%;
  margin-left: -2px !important;
  margin-right: -2px !important;
  width: auto;
  color: #656565;
  font-size: 12px;
}

.bucket-a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 1.42857;
  list-style: none;
  font-size: 12px;
  box-sizing: border-box;
  background-color: transparent;
  text-decoration: none;
  font-weight: 500;
  -webkit-flex: 1;
  height: 60px;
  outline: none;
  color: #222;
  margin-right: -2px;
  display: flex;
  position: relative;
  z-index: 1;
}

.bucket-a:before {
  top: 0;
  transform: skewX(25deg);
  border-bottom-width: 0;
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: transparent #e0e0e0;
  -webkit-border-radius: 1px;
}

.bucket-a:first-child > div:before {
  left: 0;
  border-right-width: 0;
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: #fff;
  border-width: 0px;
  border-style: solid;
  border-color: transparent #e0e0e0;
}

.bucket-a:after {
  top: 50%;
  transform: skewX(-25deg);
  border-top-width: 0;
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: transparent #e0e0e0;
  -webkit-border-radius: 1px;
}

.bucket-a-inner {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Gotham SSm", Helvetica, Arial, sans-serif;
  line-height: 1.42857;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  color: #222;
  box-sizing: border-box;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.bucket-a-inner-copy {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 1.42857;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #002949;
  box-sizing: border-box;
  display: inline !important;
  text-align: center;
}

.bucket-a .mobile,
.bucket-breadcrumb .mobile {
  display: none;
}

.bucket-breadcrumb .desktop {
  display: inline-block;
}

.bucket-a-active {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 1.42857;
  list-style: none;
  font-size: 12px;
  text-transform: uppercase;
  box-sizing: border-box;
  background-color: transparent;
  font-weight: 500;
  -webkit-flex: 1;
  height: 60px;
  outline: none;
  margin-right: -2px;
  display: flex;
  position: relative;
  z-index: 3;
  text-decoration: none !important;
  color: #fff;
}

.bucket-breadcrumb .bucket-a-active:before {
  top: 0;
  transform: skewX(25deg);
  background-color: #002949;
  border: none;
  box-sizing: content-box;
  border-bottom: 1px solid #002949;
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  -webkit-border-radius: 1px;
}

.bucket-breadcrumb .bucket-a-active:after {
  background-color: #002949;
  border: none;
  top: 50%;
  transform: skewX(-25deg);
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  -webkit-border-radius: 1px;
}

.bucket-a-active:after {
  background-color: #002949;
  border: none;
  top: 50%;
  transform: skewX(-25deg);
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  -webkit-border-radius: 1px;
  box-sizing: border-box;
}

.bucket-a-active-inner {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 1.42857;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  box-sizing: border-box;
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.bucket-a-active-inner-first {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 1.42857;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  box-sizing: border-box;
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.bucket-a-active-inner-first:before {
  background-color: #002949;
  border-color: #002949;
  left: 0;
  border-right-width: 0;
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  border-width: 2px;
  border-style: solid;
}

.bucket-a-active-inner-last {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 1.42857;
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  box-sizing: border-box;
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.bucket-a-active-inner-last:before {
  background-color: #002949;
  border-color: #002949;
  right: 2px;
  border-left-width: 0;
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  border-width: 2px;
  border-style: solid;
}

.bucket-a-active-inner-copy {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 1.42857;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  box-sizing: border-box;
  display: block !important;
  text-align: center;
}

.bucket-a-active-inner-subhead {
  line-height: 1.42857;
  list-style: none;
  font-weight: 500;
  box-sizing: border-box;
  display: block !important;
  font-size: 12px;
  text-transform: capitalize;
  color: #ffffff;
}

.bucket-a-inner-subhead {
  line-height: 1.42857;
  list-style: none;
  font-weight: 500;
  box-sizing: border-box;
  display: block !important;
  font-size: 12px;
  text-transform: capitalize;
}

.continuityWorkflowButton {
  background: #002949;
}

.continuityWorkflowCardNumber {
  background-color: #002949;
}

.input-range__slider {
  background: #002949 !important;
  border: 1px solid #002949 !important;
}

.input-range__track--active {
  background: #002949 !important;
}

.link-icon .fas {
  color: #002949;
}

.link-arrow:after {
  color: #002949;
}

.link-arrow:hover:after {
  color: #455560;
  left: 5px;
}

.link-arrow.back:hover:before {
  color: #455560;
}

.MuiIconButton-colorPrimary {
  color: #002949 !important;
}

/* 4. Header*/

.navigation {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}

.navigation .navbar .navbar-nav > li.has-child:after {
  color: #002949;
}

.navigation .navbar .navbar-nav > li a:after {
  background-color: #002949;
}

.navigation .navbar .navbar-nav > li > .child-navigation.position-bottom > li:last-child:hover a:after {
  border-color: #002949 transparent transparent transparent;
}
.navigation .navbar .navbar-nav > li > .child-navigation > li:first-child:hover a:hover:after {
  border-color: transparent transparent #002949 transparent;
}

.navigation .navbar .navbar-nav > li .child-navigation li a:hover {
  background-color: #002949;
  color: white !important;
}

.navigation .navbar .navbar-nav li .child-navigation li.has-child:after {
  color: #002949;
}

.navigation .add-your-property {
  bottom: -45px;
}

.navigation .add-your-property .btn {
  width: 45px;
  height: 45px;
}

.header-marker {
  font-size: 25px;
}

.navigation .secondary-navigation a:hover {
  color: #455560;
}

.navigation .secondary-navigation a.promoted {
  color: #002949;
}

.navigation .secondary-navigation a.promoted:hover {
  color: #455560;
}

.navigation .secondary-navigation figure {
  display: inline-block;
}

.navigation-fixed-bottom .navigation {
  bottom: 0;
  position: absolute;
  top: inherit;
}

.navigation-fixed-top .navigation {
  top: 0;
  position: fixed;
  bottom: inherit;
}

.navigation-fix-to-top {
  position: fixed !important;
  bottom: inherit !important;
  top: 0 !important;
}

.progress-bar {
  background-color: #002949;
}

.progress-bar-success {
  background-color: #5cb85c;
}

.custom-tool-tip {
  color: #002949 !important;
}

.deal-icon {
  color: #002949 !important;
}

.deal-invert-button {
  color: #002949 !important;
  background: #ffffff !important;
}

/* Specific to IE browser */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #profile-dropdown-ie {
    width: 152px;
  }

  .nav-bar-max {
    margin-bottom: 8px;
  }
}

#nav-bar-ie {
  display: -ms-grid;
  -ms-grid-columns: max-content;
}

/* browser specific styling end here */

.nav-bar-max {
  width: max-content;
}

/* 5. Footer */

#page-footer .inner {
  display: table;
  width: 100%;
}

#page-footer .inner #footer-copyright a:hover {
  color: #002949;
}

#page-footer .inner .property-thumbnail {
  background-color: #002949;
}

#about-us #ceo-section h3 {
  color: #455560;
}

#about-us #ceo-section .cite {
  width: 90%;
}

.geo-location-wrapper .btn {
  color: #455560;
}

.geo-location-wrapper .btn:hover {
  color: #002949;
}

.geo-location-wrapper .fas {
  font-size: 20px;
}

.geo-location-wrapper .text {
  background-color: #455560;
}

.geo-location-wrapper .text:after {
  border-color: transparent transparent transparent #455560;
}

#agent-detail .agency-logo:after {
  color: #002949;
}

.sm-pie-legend .legendColorBox {
  padding-right: 5px;
}

.sm-pie-legend .legendColorBox > div {
  border: #fff 0px solid !important;
}

.sm-pie-legend .legendColorBox div {
  border-radius: 100% !important;
}

.md-pie-legend {
  padding: 6px 30px;
}

.rev-pie-legend .legendColorBox {
  padding-right: 5px;
}

.rev-pie-legend .legendColorBox > div {
  border: #fff 0px solid !important;
}

.rev-pie-legend .legendColorBox div {
  border-radius: 100% !important;
}

.md-pie-legend .legendColorBox {
  padding-right: 5px;
}

.md-pie-legend .legendColorBox > div {
  border: #fff 0px solid !important;
}

.md-pie-legend .legendColorBox div {
  border-radius: 100% !important;
}

.navigation .navbar .navbar-nav > li .child-navigation li a {
  color: #333;
}

.banner {
  margin-bottom: 10px;
  margin-left: 20px;
  background: white;
}

.banner .submit {
  color: #002949;
}

.banner .submit i {
  color: #455560;
}

.bookmark:before {
  color: #002949;
}

.bookmark:hover:before {
  color: #455560;
}

.bookmark:hover .title-add {
  visibility: visible;
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  right: 30px;
}

.bookmark .title-add,
.bookmark .title-added {
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  visibility: hidden;
  color: #5a5a5a;
  font-size: 12px;
  pointer-events: none;
  position: absolute;
  top: 0px;
  right: 25px;
  text-align: right;
  width: 140px;
}

.bookmark-added {
  position: relative;
}

.bookmark-added:before {
  content: '\f004';
}

.bookmark-added:after {
  color: #002949;
}

.blurry-text {
  color: transparent;
  text-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
}

.white-blurry-text {
  color: transparent;
  text-shadow: rgba(255, 255, 255, 1) 0px 0px 5px;
}

.comments .comment .reply .fas {
  color: #002949;
}

.error-page .title header {
  color: #002949;
}

.faq .icon {
  background-color: #002949;
}

.faq .icon:after {
  border-color: transparent #002949 transparent transparent;
}

.faq header {
  color: #455560;
}
.feature-box .description h3 {
  color: #455560;
}

.feature-box .icon {
  background-color: #002949;
}

.feature-box .icon:after {
  border-color: transparent #002949 transparent transparent;
}

.fun-facts .number-wrapper figure {
  color: #455560;
}

.fun-facts .number-wrapper .number {
  color: #002949;
}

.goals .recommend-box a h4 {
  color: #002949;
}

.member h3 {
  color: #455560;
}

.member .tag {
  background-color: #002949;
}
.my-properties table thead tr th {
  background-color: #455560;
}

.my-properties table tbody tr td.actions .edit:hover i {
  color: #455560;
}

.my-properties table tbody tr td.actions .edit i {
  color: #002949;
}

.pagination li.active a {
  border-color: #455560;
  color: #455560;
}

.pagination li.active a:hover,
.pagination li.active a:active,
.pagination li.active a:focus {
  background-color: transparent;
  border-color: #455560;
  color: #455560;
}

.pagination li a:hover,
.pagination li a:active,
.pagination li a:focus {
  border-color: #002949;
  color: #002949;
}

.post-author header {
  color: #455560;
}

.price-box header {
  background-color: #002949;
}

.price-box .price {
  background-color: #455560;
}

.property .overlay .additional-info {
  background-color: #455560;
}
.property .overlay .info {
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr='#00000000',
      endColorstr='#a6000000',
      GradientType=0
    );
}

.property.masonry aside h3 {
  color: #455560;
}

.carousel-caption p {
  font-size: 18px;
}

.property-carousel .property-slide .overlay {
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr='#00000000',
      endColorstr='#a6000000',
      GradientType=0
    );
}

.property-carousel .owl-controls .owl-prev,
.property-carousel .owl-controls .owl-next {
  background-color: #002949;
}

.property-features-list li:before {
  color: #fff;
}

.property-title .actions {
  /* height: 10px; */
}

.rating img {
  background-color: #002949;
}

.rating .inner img {
  background-color: #002949;
}

.ribbon {
  background-color: #002949;
}

#search-filter h3 i {
  color: #002949;
}

.show-on-map .fas {
  color: #002949;
}

#sidebar ul.list-links li {
  color: #002949;
}
#sidebar .sidebar-navigation li.active a {
  border-color: #455560;
}

#sidebar .sidebar-navigation li.active a:after {
  border-color: transparent transparent transparent #455560;
}

#sidebar .sidebar-navigation > li a:hover {
  border-color: #002949;
}

#sidebar .sidebar-navigation > li a:hover:after {
  border-color: transparent transparent transparent #002949;
}

#sidebar .sidebar-navigation > li i {
  color: #002949;
}

#sidebar .sidebar-navigation > li ul > li:before {
  color: #002949;
}

#slider .homepage-slider .slide:after {
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr='#00000000',
      endColorstr='#a6000000',
      GradientType=0
    );
}

.show-all:after {
  color: #002949;
}

.submit-pricing table thead tr th.title {
  color: #002949;
}
.submit-pricing table tbody tr td.available {
  color: #002949;
}

.submit-pricing table tbody tr.prices td {
  color: #455560;
}

.submit-step .step-number {
  background-color: #002949;
}

.submit-step .step-number:after {
  border-color: transparent transparent transparent #002949;
}

.submission-message header {
  color: #455560;
}

.tag.price {
  background-color: #002949;
}

.tag.price a {
  background-color: #002949;
}

.timeline-item .circle .dot {
  background-color: #002949;
}

.timeline-item .circle .date {
  background-color: #002949;
}

.timeline-item .circle .date:after {
  border-color: transparent #002949 transparent transparent;
}

.tool-tip {
  color: #455560;
}

.universal-button figure {
  color: #002949;
}

.universal-button .arrow {
  color: #002949;
}

.marker-style {
  border: 3px solid #002949;
}

.cluster > div:before {
  background-color: #002949;
}
.leaflet-div-icon:after {
  border: 3px solid #002949;
}

.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
  border-top: 5px solid #455560;
}

.infobox-wrapper .infobox-inner {
  border-top: 6px solid #455560;
}

.infobox-wrapper .infobox-inner .infobox-image:after {
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr='#00000000',
      endColorstr='#a6000000',
      GradientType=0
    );
}

.infobox-wrapper .infobox-inner .infobox-image .infobox-price {
  background-color: #002949;
}

.infobox-wrapper .infobox-inner .infobox-title a {
  color: #455560;
}

.infobox-wrapper .infobox-inner .fas {
  color: #002949;
}

.marker-cluster {
  background-color: #002949;
}

.marker-cluster:hover {
  background-color: #455560;
}

.form-group .bootstrap-select .dropdown-toggle {
  background-color: #f3f3f3;
  background-color: #f3f3f3 !important;
  color: #5a5a5a;
}

.btn-lg {
  background-color: #5489b6;
  color: #fff;
}

.btn.btn-default,
select.btn-default {
  background-color: #5489b6;
  color: #fff;
  border-radius: 6px;
}

.btn.btn-default:hover,
select.btn-default:hover {
  background-color: #949ca1;
  color: #fff;
}

.btn.btn-success:hover,
select.btn-success:hover {
  background-color: #949ca1;
  color: #fff;
}

.btn.btn-info:hover,
select.btn-info:hover {
  background-color: #949ca1;
  color: #fff;
}

.btn.btn-warning:hover,
select.btn-warning:hover {
  background-color: #949ca1;
  color: #fff;
}
.checkbox.switch .icheckbox:hover {
  border-color: #002949;
}

.checkbox.switch .icheckbox:after {
  color: #455560;
}

.checkbox.switch .icheckbox:before {
  background-color: #002949;
}

.checkbox.switch .icheckbox.checked {
  border-color: #002949;
}

.checkbox.switch .icheckbox.checked:before {
  background-color: #455560;
}

.bootstrap-select .selectpicker .caret:after {
  color: #002949;
}

.bootstrap-select .selectpicker .filter-option:before {
  color: #002949;
}

.bootstrap-select .dropdown-menu a:hover,
.bootstrap-select .dropdown-menu li.selected a:hover {
  background-color: inherit;
  background-color: #f3f3f3 !important;
}

.jslider .jslider-bg .v {
  background-color: #002949;
}

.jslider .jslider-pointer {
  background: url('../images/price-range-dragger.png') no-repeat 50% 50% #002949;
}

.jslider .jslider-pointer:before {
  border-color: transparent transparent #002949 transparent;
}

.search-box .form-map input[type='text'],
.search-box .form-map input[type='email'],
.search-box .form-map input[type='search'],
.search-box .form-map input[type='password'],
.search-box .form-map input[type='number'],
.search-box .form-map textarea,
.search-box .form-map select,
.search-box .form-map .selectpicker,
.search-box .form-map .price-range {
  background-color: #455560;
}

.horizontal-search .search-box-wrapper {
  background-color: #002949;
}

.horizontal-search .search-box-wrapper .search-box .advanced-search-toggle .fas {
  color: #455560;
}

.horizontal-search .search-box-wrapper .search-box .nav-pills li.active a {
  background-color: #455560;
}

.horizontal-search .search-box-wrapper .search-box .nav-pills li a:after {
  border-color: #455560 transparent transparent transparent;
}

.horizontal-search .search-box-wrapper .search-box .form-map button {
  background-color: #455560;
  color: #fff;
}

.horizontal-search .search-box-wrapper .search-box .form-map .selectpicker .caret:after {
  color: #002949;
}

.horizontal-search .search-box-wrapper .search-box .form-map .jslider-label,
.horizontal-search .search-box-wrapper .search-box .form-map .jslider-value {
  color: #5a5a5a;
}

.horizontal-search .search-box-wrapper .search-box .advanced-search {
  padding-bottom: 20px;
}

.horizontal-search .search-box-wrapper .search-box .advanced-search h3 {
  margin-top: 0;
}

.horizontal-search-float .search-box {
  padding-right: 0;
  position: inherit;
}

.horizontal-search-float .search-box .nav-pills li.active a {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.9);
  color: #455560;
}

.horizontal-search-float .search-box .form-map input[type='text'],
.horizontal-search-float .search-box .form-map input[type='email'],
.horizontal-search-float .search-box .form-map input[type='search'],
.horizontal-search-float .search-box .form-map input[type='password'],
.horizontal-search-float .search-box .form-map input[type='number'],
.horizontal-search-float .search-box .form-map textarea,
.horizontal-search-float .search-box .form-map select,
.horizontal-search-float .search-box .form-map .selectpicker,
.horizontal-search-float .search-box .form-map .price-range {
  background-color: #455560;
}

.col-sm-4 .property .overlay .info h3 {
  text-shadow: none;
  color: #455560;
}
.navigation .navbar .navbar-nav li.mobile-submit i {
  color: #002949;
}

.navigation .navbar .navbar-nav li .child-navigation li a:hover {
  color: #002949;
}
.navigation .secondary-navigation {
  background-color: #455560;
}

.search-box-wrapper {
  background-color: #455560;
}

.search-box-wrapper .search-box .nav-pills li.active a {
  background-color: #002949;
}

.search-box-wrapper .search-box .nav-pills li a:after {
  border-color: #002949 transparent transparent transparent;
}

/* Start here */

.ribbon-price {
  background: #002949;
}

.leaderboard {
  text-align: center;
  margin: 15px 0;
}

.content-card {
  margin: 5px 10px 5px 20px;
}

.nickName {
  position: absolute;
  top: -3px;
}

.label {
  margin: 0.2em;
}

.wiggle-btn {
  -webkit-animation: btnWiggle 4s infinite;
  -moz-animation: btnWiggle 4s infinite;
  -o-animation: btnWiggle 4s infinite;
  animation: btnWiggle 4s infinite;
}

/* animation */
@-webkit-keyframes btnWiggle {
  0% {
    -webkit-transform: rotate(0deg);
  }
  5% {
    -webkit-transform: rotate(-2deg);
  }
  10% {
    -webkit-transform: rotate(2deg);
  }
  15% {
    -webkit-transform: rotate(-2deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@-o-keyframes btnWiggle {
  0% {
    -webkit-transform: rotate(0deg);
  }
  5% {
    -webkit-transform: rotate(-2deg);
  }
  10% {
    -webkit-transform: rotate(2deg);
  }
  15% {
    -webkit-transform: rotate(-2deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes btnWiggle {
  0% {
    -webkit-transform: rotate(0deg);
  }
  3% {
    -webkit-transform: rotate(-2deg);
  }
  6% {
    -webkit-transform: rotate(2deg);
  }
  9% {
    -webkit-transform: rotate(-2deg);
  }
  12% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

.borderd-content-search .title {
  margin: -10px 0 0 10px;
  background: #002949;
  color: #fff;
  padding: 3px;
  position: absolute;
  max-width: 70%;
}

.continuity_popover {
  color: #5489B6;
}

.jumbotron_education {
  background-color: #002949;
}

.cta_banner {
  background-color: #002949;
}

.jumbotron h1 {
  color: #5489B6;
}

.custom_education_banner {
  color:#5489B6;
}

.custom_education_banner_font {
  color: #ffffff;
}
